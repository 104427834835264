import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { union } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link, PageProps } from 'gatsby'

import Layout from '../layout/Layout'
import Button from '../ui-kits/Button'
import PageWrapper from '../layout/PageWrapper'
import PageTitle from '../layout/PageTitle'
import * as S from "./TagPage.styled";
import BlogPostTile from './BlogPostTile'

type Edge = {
  node: {
    frontmatter: {
      author?: string;
      authorsTitle?: string;
      date?: string;
      description?: string;
      readingTime?: number;
      title?: string;
    },
    slug: string;
  }
}

type Props = {
  pageContext: {
    tag: string;
  },
  data: {
    site: {
      siteMetadata: {
        title: string;
      }
    },
    allMdx: {
      edges: Edge[],
      totalCount: number;
    }
  }
} & PageProps;

const TagPage = (props: Props) => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState<Edge[]>([]);
 
  useEffect(() => {
    setPosts(props.data.allMdx.edges)
  }, [props.data.allMdx]);

  const tag = props.pageContext.tag
  const title = props.data.site.siteMetadata.title

  return (
    <Layout location={props.location}>
      <Helmet
        title={`${tag} | ${title}`}
        bodyAttributes={{
          type: "tags",
        }}
      />
      <PageWrapper align="left">
        <PageTitle heading={`#${tag}`} align="left"/>
        <S.TagPage>
          {union(posts).map((post) => (
            <BlogPostTile
              key={post.node.slug}
              to={`/${post.node.slug}`}
              author={post.node.frontmatter.author}
              authorsTitle={post.node.frontmatter.authorsTitle}
              title={post.node.frontmatter.title}
              description={post.node.frontmatter.description}
            />
          ))}
        </S.TagPage>
        <Button type="secondary" to="/tags">
          {t("cms.seeAll")}
        </Button>
      </PageWrapper>
    </Layout>
  )
}


export default TagPage;
