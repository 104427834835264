import { graphql } from 'gatsby'

import TagPage from "../components/cms/TagPage";

export default TagPage;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          slug
          frontmatter {
            title
            description
            author
            authorsTitle
            date(formatString: "DD.MM.YYYY")
            readingTime
          }
        }
      }
    }
  }
`
